import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';
import { useLocation, useNavigate } from 'react-router-dom';

export const MembersContext = createContext();

export function MembersContextProvider({ children }) {
    const memberTId = localStorage.getItem('memberTempId');
    const { handleLogout } = useContext(CommonContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('1');
    const [memberTempId, setMemberTempId] = useState('');
    const [addModal, setAddModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [exprienceModal, setExprienceModal] = useState(false);
    const [paymentsModal, setPaymentsModal] = useState(false);
    const [mStatusModal, setMStatusModal] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [desListData, setDesListData] = useState([]);
    const [mCategoryDrop, setMCategoryDrop] = useState([]);
    const [memberDropDown, setMemberDropDown] = useState([]);
    const [meetingsDropDown, setMeetingsDropDown] = useState([]);
    const [mClassDrop, setMClassDrop] = useState([]);
    const [assetsClassDrop, setAssetsClassDrop] = useState([]);
    const [aAssetsClassDrop, setAAssetsClassDrop] = useState([]);
    const [universityDrop, setUniversityDrop] = useState([]);
    const [feeDrop, setFeeDrop] = useState([]);
    const [desDropData, setDesDropData] = useState([]);
    const [mCategoryData, setMCategoryData] = useState([]);
    const [mClassData, setMClassData] = useState([]);
    const [meetingData, setMeetingData] = useState([]);
    const [meetingCount, setMeetingCount] = useState([]);
    const [councilData, setCouncilData] = useState([]);
    const [councilCount, setCouncilCount] = useState([]);
    const [meetingMembers, setMeetingMembers] = useState([]);
    const [infoData, setInfoData] = useState([]);
    const [memberListData, setMemberListData] = useState([]);
    const [experienceListData, setExperienceListData] = useState([]);
    const [paymentsData, setPaymentsData] = useState([]);
    const [mStatusData, setMStatusData] = useState([]);
    const [renewalHistory, setRenewalHistory] = useState([]);
    const [remarksListData, setRemarksListData] = useState([]);
    const [cCListData, setCCListData] = useState([]);
    const [individualListData, setIndividualListData] = useState([]);
    const [individualListCount, setIndividualListCount] = useState('');
    const [qualificationData, setQualificationData] = useState([]);
    const [locationData, setLocationData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [formTemp, setFormTemp] = useState();
    const [cardData, setCardData] = useState('');
    const [filters, setFilters] = useState({
        searchBy: 'first name',
        searchValue: '',
        member_type_id: '',
        start_date: null,
        end_date: null,
        profile_status: '',
        registration_status: '',
        asset_class_id: '',
        member_category_id: '',
        memberClass: '',
        is_member: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [locationState, setLocationState] = useState('');

    useEffect(() => {
        if (location?.state) {
            setLocationState(location.state);
        }
    }, [location]);
console.log('locationState', locationState);
    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const handleViewModals = () => {
        setViewModal(true);
    };
    const closeViewModal = () => {
        setViewModal(false);
        setFormTemp();
    };
    const exprienceAddModals = () => {
        setExprienceModal(true);
        setIsEdit(false);
    };

    const exprienceCloseModal = () => {
        setExprienceModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const paymentsAddModals = () => {
        setPaymentsModal(true);
        setIsEdit(false);
    };

    const paymentsCloseModal = () => {
        setPaymentsModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const mStatusAddModals = () => {
        setMStatusModal(true);
        setIsEdit(false);
    };

    const mStatusCloseModal = () => {
        setMStatusModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const remarksAddModals = () => {
        setRemarksModal(true);
        setIsEdit(false);
    };

    const remarksCloseModal = () => {
        setRemarksModal(false);
        setIsEdit(false);
        setFormTemp();
    };
    const onTopCard = async () => {
        const postReq = {}
        if (memberTempId) {
            postReq.member_id = memberTempId;
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/top-card', postReq);
            if (response.data.s === 1) {
                setCardData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDesLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/list');
            if (response.data.s === 1) {
                setDesListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDesDropApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/dropdown');
            if (response.data.s === 1) {
                setDesDropData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMCategoryDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/category/dropdown');
            if (response.data.s === 1) {
                setMCategoryDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMeetingDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/meetings/dropdown');
            if (response.data.s === 1) {
                setMeetingsDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMemberDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/dropdown');
            if (response.data.s === 1) {
                setMemberDropDown(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMClassDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/class/dropdown');
            if (response.data.s === 1) {
                setMClassDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onAssetsClassDrop = async (parentId) => {
        const postReq = {}
        if (parentId) {
            postReq.parent_id = parentId
        }
        setIsLoading(true);
        try {
            const response = await commonApi.post('assets-class/dropdown', postReq);
            if (response.data.s === 1) {
                if (parentId) {
                    setAAssetsClassDrop(response.data.data);
                } else {
                    setAssetsClassDrop(response.data.data);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onUniversityDrop = async (parentId) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/qualification/universities');
            if (response.data.s === 1) {
                setUniversityDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onfeeDrop = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('fee-structures/dropdown');
            if (response.data.s === 1) {
                setFeeDrop(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMClassApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/class/list');
            if (response.data.s === 1) {
                setMClassData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMeetingApi = async (currentPage, itemsPerPage) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post(`members/meetings/list?page=${currentPage}&per_page=${itemsPerPage}`);
            if (response.data.s === 1) {
                setMeetingData(response.data.data);
                setMeetingCount(response.data.TotalRows);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onCouncilApi = async (currentPage, itemsPerPage) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post(`members/council/list?page=${currentPage}&per_page=${itemsPerPage}`);
            if (response.data.s === 1) {
                const transformedData = response.data.data.map(item => ({
                    ...item,
                    member_ids: item?.member_ids?.split(',').map(id => id.trim()),
                    member_names: item?.member_names?.split(',').map(name => name.trim()),
                }));
                setCouncilData(transformedData);
                setCouncilCount(response.data.TotalRows);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onMeetingMember = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/meetings/users/list', { meeting_id: location?.state?.id });
            if (response.data.s === 1) {
                setMeetingMembers(response.data.data);
                setInfoData(response.data.cards);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMCategoryApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/category/list');
            if (response.data.s === 1) {
                setMCategoryData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const onQualificationApi = async () => {
        setDataLoading(true);
        const postReq = {};
        if (memberTempId) {
            postReq.member_id = memberTempId;
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/qualification/list', postReq);
            if (response.data.s === 1) {
                setQualificationData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setDataLoading(false);
        }
    }
    const onMemberLoadApi = async (data) => {
        console.log('data', data);
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/insti-mem/list', { branch_id: location?.state?.id });
            if (response.data.s === 1) {
                setMemberListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onExport = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/export', filters);
            if (response.data.s === 1) {
                navigate('/downloads');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMeetingExport = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/meetings/export', { id: id });
            if (response.data.s === 1) {
                navigate('/downloads');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onRemarksLoadApi = async () => {
        setIsLoading(true);
        const postReq = {}
        if (memberTempId) {
            postReq.member_id = memberTempId
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/remarks-list', postReq);
            if (response.data.s === 1) {
                setRemarksListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onCCLoadApi = async () => {
        setIsLoading(true);
        const postReq = {}
        if (memberTempId) {
            postReq.member_id = memberTempId
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/change-category-list', postReq);
            if (response.data.s === 1) {
                setCCListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onExprienceLoadApi = async () => {
        setDataLoading(true);
        const postReq = {};
        if (memberTempId) {
            postReq.member_id = memberTempId;
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/experience/list', postReq);
            if (response.data.s === 1) {
                setExperienceListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setDataLoading(false);
        }
    };
    const onPaymentLoadApi = async () => {
        setIsLoading(true);
        const postReq = {}
        if (memberTempId) {
            postReq.member_id = memberTempId
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/payments/list', postReq);
            if (response.data.s === 1) {
                setPaymentsData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMemberStatusApi = async () => {
        setIsLoading(true);
        const postReq = {}
        if (memberTempId) {
            postReq.member_id = memberTempId
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        try {
            const response = await commonApi.post('members/status/list', postReq);
            if (response.data.s === 1) {
                setMStatusData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onRenewalHistoryApi = async () => {
        const postReq = {};
        if (memberTempId) {
            postReq.member_id = memberTempId;
        }
        if (locationState?.is_temp) {
            postReq.is_temp = locationState?.is_temp;
        }
        setIsLoading(true);
        try {
            const response = await commonApi.post('members/renewal-list', postReq);
            if (response.data.s === 1) {
                setRenewalHistory(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onIndividualLoadApi = async (currentPage, itemsPerPage) => {
        setIndividualListData([]);
        setIndividualListCount('');
        setIsLoading(true);
        try {
            const response = await commonApi.post(`members/list?page=${currentPage}&per_page=${itemsPerPage}`, filters);
            if (response.data.s === 1) {
                setIndividualListData(response.data.data);
                setIndividualListCount(response?.data?.TotalRows);
            } else {
                setIndividualListData([]);
                setIndividualListCount('');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (memberTId) {
            setMemberTempId(memberTId);
        } else {
            setMemberTempId('');
        }
    }, [memberTId]);
    useEffect(() => {
        // if (memberTempId) {
        //     onRemarksLoadApi();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberTempId]);
    useEffect(() => {
        if ((location.pathname === '/member-profile' || location.pathname === '/member-view') && location.state?.id) {
            setMemberTempId(location.state?.id);
        }
        if (location.pathname !== '/member-profile' && location.pathname !== '/member-view') {
            localStorage.removeItem('memberTempId');
        }

        onMCategoryDrop();
        onMClassDrop();
        onAssetsClassDrop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(() => {
        if (location?.state) {
            setLocationData(location?.state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(() => {
        // onMemberDrop();
    }, []);

    const contextValue = {
        // memberTempId,
        setMemberTempId,
        handleViewModals,
        closeViewModal,
        viewModal,
        setViewModal,
        dataLoading,
        setDataLoading,
        infoData,
        activeTab,
        setActiveTab,
        locationState,
        setLocationState,
        onMeetingExport,
        isReset,
        setIsReset,
        onMemberDrop,
        memberDropDown,
        onExport,
        itemsPerPage,
        setItemsPerPage,
        currentPage,
        setCurrentPage,
        setFilters,
        filters,
        onMeetingMember,
        meetingMembers,
        setMeetingMembers,
        cardData,
        onTopCard,
        onMeetingDrop,
        meetingsDropDown,
        mStatusCloseModal,
        mStatusAddModals,
        mStatusModal,
        setMStatusModal,
        onRenewalHistoryApi,
        renewalHistory,
        onMemberStatusApi,
        mStatusData,
        setMStatusData,
        onCouncilApi,
        councilData,
        councilCount,
        onMeetingApi,
        meetingCount,
        meetingData,
        setMeetingData,
        paymentsCloseModal,
        paymentsAddModals,
        paymentsModal,
        setPaymentsModal,
        onPaymentLoadApi,
        paymentsData,
        setPaymentsData,
        onfeeDrop,
        feeDrop,
        onUniversityDrop,
        universityDrop,
        setUniversityDrop,
        onExprienceLoadApi,
        experienceListData,
        setExperienceListData,
        remarksListData,
        setRemarksListData,
        onRemarksLoadApi,
        cCListData,
        setCCListData,
        onCCLoadApi,
        onAssetsClassDrop,
        assetsClassDrop,
        aAssetsClassDrop,
        mClassDrop,
        mCategoryDrop,
        memberTempId,
        individualListCount,
        individualListData,
        setIndividualListData,
        onIndividualLoadApi,
        remarksModal,
        setRemarksModal,
        remarksCloseModal,
        remarksAddModals,
        exprienceCloseModal,
        exprienceAddModals,
        exprienceModal,
        setExprienceModal,
        onQualificationApi,
        onMemberLoadApi,
        mClassData,
        setMClassData,
        onMClassApi,
        locationData,
        setLocationData,
        onMCategoryApi,
        memberListData,
        setMemberListData,
        qualificationData,
        setQualificationData,
        onDesDropApi,
        desDropData,
        onDesLoadApi,
        mCategoryData,
        setMCategoryData,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        desListData,
        setDesListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
    };

    return <MembersContext.Provider value={contextValue}>{children}</MembersContext.Provider>;
}
