import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const FeeStructureContext = createContext();

export function FeeStructureContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState({});
    const [isReset, setIsReset] = useState(false);
    const [filters, setFilters] = useState({
        searchBy: 'fee name',
        searchValue: '',
        member_type_id: '',
        start_date: null,
        end_date: null,
        financial_year: '',
        fee_cat_id: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [listCount, setListCount] = useState('');

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({});
    };

    const onLoadApi = async (currentPage, itemsPerPage) => {
        setDataLoading(true);
        setListData([]);
        setListCount('');
        try {
            const response = await commonApi.post(`fee-structures/list?page=${currentPage}&limit=${itemsPerPage}`, filters);
            if (response.data.s === 1) {
                setListData(response.data.data);
                setListCount(response.data.TotalRows);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setDataLoading(false);
        }
    };


    // useEffect(() => {
    //     onLoadApi();
    // }, []);

    const contextValue = {
        dataLoading, 
        setDataLoading,
        listCount,
        filters,
        setFilters,
        isReset,
        setIsReset,
        itemsPerPage,
        setItemsPerPage,
        currentPage,
        setCurrentPage,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onLoadApi
    };

    return <FeeStructureContext.Provider value={contextValue}>{children}</FeeStructureContext.Provider>;
}
