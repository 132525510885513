import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import '../src/assets/theme-bordered.css';
import Sidebar from './component/common/Sidebar';
import Header from './component/common/Header';
import { CommonContextProvider } from './context/CommonContext';
import Banner from './component/banner';
import SignIn from './component/common/Signin';
import City from './component/city';
import State from './component/state';
import Category from './component/category';
import Pincode from './component/pincode';
import Cms from './component/cms';
// import Cms2 from './component/cms2';
import Popup from './component/popup';
import Filemanager from './component/filemanager';
import Contact from './component/contact';
import EJournal from './component/eJournal';
import Journey from './component/journey';

import Seo from './component/seo';

import SiteSetting from './component/common/SiteSetting';
import Setting from './component/setting';
import Alerts from './component/alerts';
import Tender from './component/tender';
import News from './component/news';
import Blogs from './component/blogs';
import Designation from './component/branches/designation';
import Branch from './component/branches/branch';
import Members from './component/branches/members';
import Institutional from './component/members/institutional';
import Announcements from './component/branches/announcements';
import Advisory from './component/branches/advisory';
import Article from './component/article';
import Testimonial from './component/testimonial';
import Event from './component/events/event';
import Gallery from './component/events/gallery';
import AssetsClass from './component/assetsClass';
import MemberCategory from './component/members/memberCategory';
import MemberClass from './component/members/memberClass';
import Individual from './component/members/individual';
import MemberProfile from './component/members/memberProfile';
import FeeStructure from './component/feeStructure';
import FinancialYear from './component/financialYear';
import Meeting from './component/members/meeting';
import MeetingMebers from './component/members/meetingMebers';
import Books from './component/books';
import HomeCard from './component/homeCard';
import FaqCategory from './component/faqCategory';
import Courses from './component/courses';
import Faq from './component/faq';
import CareerCategory from './component/careerCategory';
import Career from './component/career';
import Download from './component/download';
import Council from './component/members/council';
import Users from './component/users';
import FeesCategory from './component/feesCategory';
import Invoice from './component/invoice';
import Arrears from './component/arrears';
import CreditNote from './component/creditNote';
import HomeMenu from './component/homeMenu';
import BranchLogin from './component/common/BranchLogin';
import BranchsLogin from './component/branches/branchsLogin';
import Grievance from './component/grievance';


function App() {
  let location = useLocation();
  return (
    <CommonContextProvider>
      <div className="App">
        {!['/login', '/branch-login'].includes(location.pathname) &&
          <>
            <Sidebar />
            <Header />
          </>
        }
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route exact path="/" element={<Banner />} />
            <Route exact path="/login" element={<SignIn />} />
            <Route exact path="/branch-login" element={<BranchLogin />} />
            <Route exact path="/city" element={<City />} />
            <Route exact path="/state" element={<State />} />
            <Route exact path="/category" element={<Category />} />
            <Route exact path="/assets-class" element={<AssetsClass />} />
            <Route exact path="/pincode" element={<Pincode />} />
            <Route exact path="/cms" element={<Cms />} />
            {/* <Route exact path="/cms2" element={<Cms2 />} /> */}
            <Route exact path="/seo" element={<Seo />} />
            <Route exact path="/popups" element={<Popup />} />
            <Route exact path="/file-manager" element={<Filemanager />} />
            <Route exact path="/contactus" element={<Contact />} />
            <Route exact path="/e-Journal" element={<EJournal />} />
            <Route exact path="/iov-journey" element={<Journey />} />
            <Route exact path="/site-settings" element={<SiteSetting />} />
            <Route exact path="/profile" element={<Setting />} />
            <Route exact path="/alerts" element={<Alerts />} />
            <Route exact path="/tender" element={<Tender />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/branch/designation" element={<Designation />} />
            <Route exact path="/branch" element={<Branch />} />
            <Route exact path="/branch/members" element={<Members />} />
            <Route exact path="/branch/login-details" element={<BranchsLogin />} />
            <Route exact path="/branch/announcements" element={<Announcements />} />
            <Route exact path="/advisory" element={<Advisory />} />
            <Route exact path="/institutional-member" element={<Institutional />} />
            <Route exact path="/individual-member" element={<Individual />} />
            <Route exact path="/member-profile" element={<MemberProfile />} />
            <Route exact path="/member-view" element={<MemberProfile />} />
            <Route exact path="/member-category" element={<MemberCategory />} />
            <Route exact path="/member-class" element={<MemberClass />} />
            <Route exact path="/article" element={<Article />} />
            <Route exact path="/testimonials" element={<Testimonial />} />
            <Route exact path="/event" element={<Event />} />
            <Route exact path="/event-gallery" element={<Gallery />} />
            <Route exact path="/fees-structure" element={<FeeStructure />} />
            <Route exact path="/financialYear" element={<FinancialYear />} />
            <Route exact path="/meetings" element={<Meeting />} />
            <Route exact path="/meeting-members" element={<MeetingMebers />} />
            <Route exact path="/books" element={<Books />} />
            <Route exact path="/home-cards" element={<HomeCard />} />
            <Route exact path="/faq-category" element={<FaqCategory />} />
            <Route exact path="/courses" element={<Courses />} />
            <Route exact path="/faq" element={<Faq />} />
            <Route exact path="/career-category" element={<CareerCategory />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/downloads" element={<Download />} />
            <Route exact path="/council" element={<Council />} />
            <Route exact path="/manage-users" element={<Users />} />
            <Route exact path="/fees-category" element={<FeesCategory />} />
            <Route exact path="/payments" element={<Invoice />} />
            <Route exact path="/arrears" element={<Arrears />} />
            <Route exact path="/credit-note" element={<CreditNote />} />
            <Route exact path="/home-menus" element={<HomeMenu />} />
            <Route exact path="/grievance" element={<Grievance />} />
          </Routes>
        </Suspense>
      </div>
    </CommonContextProvider>
  );
}

export default App;
