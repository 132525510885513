import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGooglePlusG, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import CryptoJS from "crypto-js";
import { CommonContext } from "../../context/CommonContext";
import { useNavigate } from "react-router-dom";
import "../../assets/signin.scss";
import commonApi from "../../utiles/api";
import Logo from "../../assets/image/logo.png";
import { faImagePortrait } from "@fortawesome/free-solid-svg-icons";

const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};
const secretKey = "app-iov";

const BranchLogin = () => {
  const { setUser } = useContext(CommonContext);
  const [formData, setFormData] = useState({ email: "", password: "", mobile: "", otp: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("password");
  const [otpSent, setOtpSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    if (activeTab === "password") {
      if (!validateEmail(formData.email)) {
        setError("Invalid email format");
        return false;
      }
      if (!formData.password) {
        setError("Password is required");
        return false;
      }
    } else if (activeTab === "otp") {
      if (!formData.mobile) {
        setError("Mobile number is required");
        return false;
      }
      if (!otpSent) {
        return true;
      }
      if (!formData.otp) {
        setError("OTP is required");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        if (activeTab === "password") {
          const response = await commonApi.post("/branch/login", {
            email: formData.email,
            password: formData.password,
          });
          if (response.data.s === 1) {
            const userData = response?.data?.data;
            const encryptedUserData = encryptData(userData, secretKey);
            localStorage.setItem("userIOV", encryptedUserData);
            localStorage.setItem("iovToken", response?.data?.token);
            localStorage.setItem("iovConfig", JSON.stringify(response?.data?.variables));
            setUser(userData);
            navigate("/");
          } else {
            setError(response.data.msg || "Sign in failed");
          }
        } else {
          if (!otpSent) {
            const response = await commonApi.post("/branch/login", { mobile: formData.mobile });
            if (response.data.s === 1) {
              setOtpSent(true);
              setError("OTP sent to your mobile");
            } else {
              setError(response.data.msg || "Failed to send OTP");
            }
          } else {
            const response = await commonApi.post("/branch/login", {
              mobile: formData.mobile,
              otp: formData.otp,
            });
            if (response.data.s === 1) {
              const userData = response?.data?.data;
              const encryptedUserData = encryptData(userData, secretKey);
              localStorage.setItem("memberIOV", encryptedUserData);
              localStorage.setItem("memberToken", response?.data?.token);
              localStorage.setItem("memberConfig", JSON.stringify(response?.data?.variables));
              setUser(userData);
              navigate("/profile");
            } else {
              setError(response.data.msg || "Sign in failed");
            }
          }
        }
      } catch (err) {
        setError(err.response?.data?.msg || err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setError("");
      setFormData({ email: "", password: "", mobile: "", otp: "" });
      setOtpSent(false);
    }
  };

  return (
    <Container fluid className="signin-container">
      <Row className="align-items-center justify-content-center min-vh-100">
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-4">
              <div className="card">
                <div className="card-body">
                      <h4 className="mb-2">Welcome to IOV! 👋</h4>
                      <p className="mb-4">Please sign-in to your account and start.</p>
                  <Nav tabs className="mb-3">
                    <NavItem>
                      <NavLink
                        className={activeTab === "password" ? "active" : ""}
                        onClick={() => toggleTab("password")}
                      >
                        Login with Password
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "otp" ? "active" : ""}
                        onClick={() => toggleTab("otp")}
                      >
                        Login with OTP
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="w-100">
                    <TabPane tabId="password">
                      <Form onSubmit={handleSubmit} className="custom-form">
                        {error && <Alert color="danger">{error}</Alert>}
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="password">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Enter your password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        <Button color="primary" type="submit" disabled={loading} block>
                          {loading ? "Signing in..." : "Sign In"}
                        </Button>
                      </Form>
                    </TabPane>
                    <TabPane tabId="otp">
                      <h4 className="mb-2">Login with OTP</h4>
                      <p className="mb-4">Please enter your mobile number to receive an OTP.</p>
                      <Form onSubmit={handleSubmit} className="custom-form">
                        {error && <Alert color="danger">{error}</Alert>}
                        <FormGroup>
                          <Label for="mobile">Mobile Number</Label>
                          <Input
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter your mobile number"
                            value={formData.mobile}
                            onChange={handleChange}
                            required
                          />
                        </FormGroup>
                        {!otpSent ? (
                          <Button color="primary" type="submit" disabled={loading} block>
                            {loading ? "Sending OTP..." : "Send OTP"}
                          </Button>
                        ) : (
                          <>
                            <FormGroup>
                              <Label for="otp">OTP</Label>
                              <Input
                                type="text"
                                name="otp"
                                id="otp"
                                placeholder="Enter your OTP"
                                value={formData.otp}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>
                            <Button color="primary" type="submit" disabled={loading} block>
                              {loading ? "Verifying..." : "Verify OTP"}
                            </Button>
                          </>
                        )}
                      </Form>
                    </TabPane>
                  </TabContent>
                  <div className="divider my-4">
                    <div className="divider-text">or</div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-facebook me-3"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-google-plus me-3"
                    >
                      <FontAwesomeIcon icon={faGooglePlusG} />
                    </a>
                    <a
                      href="javascript:;"
                      className="btn btn-icon btn-label-twitter"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default BranchLogin;
