import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';

export const HomeMenuContext = createContext();

export function HomeMenuContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState({
        parent_id: '',
        title: '',
        url: '',
        status: '1',
        type: '',
        sort_order: '',
    });

    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({
            parent_id: '',
            title: '',
            url: '',
            status: '1',
            type: '',
            sort_order: '',
        });
    };

    const onLoadApi = async (payload) => {
        setDataLoading(true);
        try {
            const response = await commonApi.post('menu/list', payload);
            if (response.data.s === 1) {
                if (payload?.is_active === 1) {
                    setIsSorting(true);
                } else {
                    setIsSorting(false);
                }
                setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setDataLoading(false);
        }
    };


    useEffect(() => {
        onLoadApi();
    }, []);

    const contextValue = {
        dataLoading,
        setDataLoading,
        onLoadApi,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSorting,
        isLoading,
        setIsLoading,
        setIsSorting
    };

    return <HomeMenuContext.Provider value={contextValue}>{children}</HomeMenuContext.Provider>;
}
